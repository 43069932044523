define('models/user/migrationModel',["knockout"],
    function (ko) {
        function migrate() {
            var self = this;
            self.validateEmail = ko.observable(false);
            self.userName = ko.observable("")
                .extend({ required: { message: 'Email is required.', onlyIf: function () { return self.validateEmail() === true; } } })
                .extend({ email: { message: 'Invalid Email.', onlyIf: function () { return self.validateEmail() === true; } } })
                .extend({ maxLength: { params: 75, message: 'Invalid Email.' } });
            self.userName.subscribe(function (userName) {
                self.userName(userName.trim());
            });
            self.validatePassword = ko.observable(false);
            self.password = ko.observable("")
                .extend({ required: { message: 'Password is required.', onlyIf: function () { return self.validatePassword() === true; } } })


            self.rememberMe = ko.observable(false);
            

            self.password.subscribe(function (newValue) {
                if (newValue.length >= 128) {
                    $("#maxPasswordLength").show();
                } else {
                    $("#maxPasswordLength").hide();
                }
            })

            self.update = function (data) {
                if (data) {
                    self.userName(data.userName);
                    self.password(data.password);
                    self.rememberMe(data.rememberMe);
                    
                }
            };
        }

        var vm = {
            migrate: migrate
        }

        return vm;
    });
