define('viewmodels/userModule/inHereUpgrade',['plugins/router', 'durandal/app', 'services/security', 'knockout', 'jquery', 'plugins/http', 'global/session', 'config', 'alertNotification', 'migrationModel', 'jquerycapslock', 'services/logger'],
    function (router, app, security, ko, $, https, session, config, alerter, migrationModel, jquerycapslock, logger) {
        var migrationModel = new migrationModel.migrate();
        var loginError = ko.observable('');
        var isApproved = ko.observable('');
        var userInfo;
        var userSecurityInfo;
        var isNewLLAEntry;
        var isLoggedIn = ko.observable(false);


        var vm = {
            activate: activate,
            deactivate: resetControls,
            goBack: goBack,
            title: 'login',
            session: session,
            setFocus: ko.observable(true),
            migrationModel: migrationModel,
            brands: [new Brand('Chicago Title', '1'), new Brand('Ticor Title', '2')],
            brand: ko.observable(),
            loaded: false,
            login, login,
            getUserInfo: getUserInfo,
            attached: AfterLoad,
            forgotPassword: forgotPassword,
            password_mouseout: password_mouseout,
            forgotpassword_mouseout: forgotpassword_mouseout,
            handleKeyUp: handleKeyUp,
            loginError: loginError,
            onBlurEmail: onBlurEmail,
            isApproved: isApproved,
            session: session,
            showError: showError,
            cancelUpgrade: cancelUpgrade,
            compositionComplete: function () {
                $('#mdUpgradeInfoPopup').modal('show');
            }
        };

        vm.validationErrors = ko.validation.group([vm.migrationModel.userName, vm.migrationModel.password]);

        return vm;

        function onBlurEmail() {
            loginError('');
        }

        function handleKeyUp(d, e) {
            if (e.keyCode == 13) {
                login();
            }
        }

        function Brand(name, id) {
            this.name = name;
            this.id = id;
        };

        function password_mouseout() {
            $('.loginPassword').trigger("blur");
        }

        function forgotpassword_mouseout() {
            $('.forgotPasswordlnk').trigger("blur");
        }

        
        function activate() {
            //reset();
        }

        function reset() {

            session.clearAccessToken();
            session.clearACSFToken();
            vm.migrationModel.userName("");
            vm.migrationModel.password("");
            vm.migrationModel.rememberMe(false);
            //vm.loginModel.userName.clearError();
            //vm.loginModel.password.clearError();
            vm.setFocus(true);
            vm.validationErrors.showAllMessages(false);
            loginError('');
        }

        function goBack(complete) {

            router.navigateBack();
        }

        function cancelUpgrade(complete) {

            window.location = window.location.origin;
        }

        function resetControls() {
            $('#LoginUserName').val('');
            $('#LoginPassword').val('');
            vm.migrationModel.userName("");
            vm.migrationModel.password("");
        }

        function login() {

            loginError('');

            if ($('#LoginUserName').val().length > 0) {
                vm.migrationModel.userName($('#LoginUserName').val());
                vm.migrationModel.validateEmail(true);
            }

            if ($('#LoginPassword').val().length > 0) {
                vm.migrationModel.password($('#LoginPassword').val());
                vm.migrationModel.validatePassword(true);
            }

            if (vm.validationErrors().length > 0) {

                vm.validationErrors.showAllMessages();
                return;
            }

            //if (localStorage["logout"] == undefined || localStorage["logout"] == null)
            //    localStorage["logout"] = true;
            //localStorage.removeItem("logout");

            session.isBusy(true);
            session.isAjaxPost(true);
            session.clearAccessToken();
            session.clearACSFToken();
            session.clearRecentSearchesFromLocalStorage();
            loginError('');
            security.login({
                grant_type: "password",
                username: vm.migrationModel.userName(),
                password: vm.migrationModel.password(),
                login_type: "web",
                upgrade: "true",
                token: "",
                remember: vm.migrationModel.rememberMe(false)
            }).done(function (data) {
                if (data.userName && data.access_token) {
                    
                    data.access_token = null;
                    session.isBusy(true);
                    isLoggedIn(false);
                    session.isLoggedIn(false);
                    session.clearAccessToken();
                    session.clearACSFToken();
                    if (data.upgradedActiveAcct == 'False') {
                        alerter.success("Your account has been successfully upgraded.  You will receive an activation email to finalize the upgrade process", { timeOut: 0 })    
                    } else {
                        alerter.success("Your account has been upgraded.  As an existing member of the inHere platform, you can use your inHere credentials to login to " + session.brandServicesName(), { timeOut: 0 })    
                    }
                    
                    //setTimeout(function () { window.location = window.location.origin; }, 8000);

                } else {
                    logger.log({
                        message: "Error Migrating User.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                    alerter.error("There was an error processing your upgrade request, please contact your Sales Representative or an administrator.", { timeOut: 0 });
                    //setTimeout(function () { window.location = window.location.origin; }, 8000);
                }
            }).fail(function (data) {
                var error = JSON.parse(data.responseText)
                console.log(error);
                loginError(error.error_description);
                vm.migrationModel.password("");
                vm.setFocus(true);
                vm.validationErrors.showAllMessages(false);
                //setTimeout(function () { window.location = window.location.origin; }, 8000);


                //console.log(data);
            }).always(function () {
                vm.migrationModel.validatePassword(false);
                vm.migrationModel.validateEmail(false);
                session.isBusy(false);
            }).failJSON(function (data) {
                if (data && data.error_description) {
                    logger.log({
                        message: data.error_description,
                        data: data.error_description,
                        showToast: false,
                        type: "error"
                    });
                } else {
                    logger.log({
                        message: "Error logging in.",
                        data: "",
                        showToast: false,
                        type: "error"
                    });
                }

            })
            

        }

        function getUserInfo() {
            var url = $.getBasePath() + "api/User/GetUserInfoByEmail";
            return https.get(url, {
                email: vm.migrationModel.userName()
            })
                .done(function (result) {
                    userInfo = result;
                })
                .fail(function () {
                    console.log("Couldn't retrieve User information");
                });
        }

        //function showOktaUpgradePreviewPopUp() {
        //    //Need markup here with tag for serv name

        //     popupMarkUp = ''
        //    app.showMessage(session.ngPopUpText().replace('[servName]', session.brandServicesName()),
        //        'Important Announcement',
        //        ['Continue'],
        //        true,
        //        {
        //            "secondaryButtonClass": "btn-primary",
        //            style: {
        //                'max-width': "600px",
        //                'max-height': "50vh!important"
        //            }
        //        });
        //    return;
        //}

        function setToken(callbackfunc) {
            return https.get(config.getTokenUrl)
                .done(function (result) {
                    if (result) {
                        session.storeACSFToken(result);
                        $.ajaxSetup({
                            headers:
                            {
                                "X-XSRF-Token": result.formToken + ":" + result.cookieToken
                            }
                        });
                        callbackfunc();
                    }
                }).fail(function () {
                    alerter.error('Error getting token information', 'User Information', { timeOut: 0 });
                });
        }

        function AfterLoad() {

            //if ($('#hdnIsLogin').val() == "true") {
            //    $('#page-wrapper').attr('style', 'margin-left:0!important');
            //}

            $(window).on("capsOn", function (e) {
                if ($("#LoginPassword:focus").length > 0) {
                    $("#capsPasswordWarning").show();
                }
            });
            $(window).on("capsOff capsUnknown", function (e) {
                $("#capsPasswordWarning").hide();
            });
            $("#LoginPassword").on("focusout", function (e) {
                $("#capsPasswordWarning").hide();

            });
            $("#LoginPassword").on("focusin", function (e) {
                if ($(window).capslockstate("state") === true) {
                    $("#capsPasswordWarning").show();


                }
            });
            $(window).capslockstate();
        }

        function forgotPassword() {
            router.navigate('forgotPassword', { replace: true, trigger: true });
        }

        function showError(err) {
            const errorEl = document.createElement('div');
            errorEl.textContent = 'Error! ' + err.error_description;
            document.body.insertBefore(
                errorEl,
                document.body.firstChild
            );
        }

        //function reBindLogo1() {

        //    console.log('reBindLogo called.')
        //    console.log('user brand - ' + session.userBrandId());
        //   // vm.title('logo updated');

        //    if (session.userBrandId() == 1) {
        //       vm.imgPath('../Content/images/Brands/ChicagoTitle.png');
        //    }
        //    else {
        //       vm.imgPath('../Content/images/Brands/TicorTitle.png');
        //    }

        //}
    });
