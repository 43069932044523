define('viewmodels/_Header',['plugins/router', 'global/session', 'knockout', 'config', 'plugins/http', 'shell', 'alertNotification','addressSearchVM'],
    function (router, session, ko, config, https, shell, alert, addressSearchVM) {
        var headerSearchInProgress = ko.observable(false);
        //var genericLogoURL = ko.observable('');
        var srchText = '';
        var autosuggestManager = null;
        var logoComponent = ko.observable('');
        var vm = {
            activate: activate,
            compositionComplete: compositionComplete,
            session: session,
            sso: sso,
            shell: shell,
            searchClick: searchClick,
            initAutoSuggest: initAutoSuggest,
            searchClear: searchClear,
            //handleLogoClick: handleLogoClick,
            compositionComplete: initAutoSuggest,
            headerSearchInProgress: headerSearchInProgress,
            MobileSideNavToggle: MobileSideNavToggle,
            SkipToMainContent: SkipToMainContent,
            toggleHighContrastCss: toggleHighContrastCss,
            logoComponent: logoComponent
            //genericLogoURL: genericLogoURL
        }

        
        //compositionComplete:initAutoSuggest

        return vm;

        function compositionComplete() {
            //genericLogoURL(' background-image:url("https://dev.passport.fntic.com/Content/images/Brands/FNTitle.png")');
            //genericLogoURL('genericLogoURL');
            session.validateInput();
        }

        function toggleHighContrastCss() {
            var hc = '<link rel="stylesheet" href="/Content/highContrast.css" id="hccss"/>';

            $('#btntoggleHighContrast').click();
            if (!$('#hccss').length) {
                $('head').append(hc);
                session.isHighContrastEnabled(true);
            } else {
                session.isHighContrastEnabled(false);
                $('#hccss').remove();
            }
            //toggleCarouselImages();
        }

        function toggleCarouselImages() {
            var carousels = $('.carousel-item');

            if (carousels && carousels.length > 0) {
                if (!session.isHighContrastEnabled()) {
                    if (session.carouselClasses().length == carousels.length) {
                        $.each(carousels, function (index, carousel) {
                            $(carousel).addClass(session.carouselClasses()[index]);
                        });
                        session.carouselClasses([]);
                    }
                }
                else {
                    $.each(carousels, function (index, carousel) {
                        $.each(carousel.classList, function (index, cssClass) {
                            if (cssClass != "carousel-item" && cssClass != "active" && (cssClass != "carousel-item-height" || cssClass != "OKTA-carousel-item-height")) {
                                if (cssClass != undefined) {
                                    session.carouselClasses().push(cssClass);
                                }
                                $(carousel).removeClass(cssClass);
                            }
                        });
                    });
                }
            }
        }

        function SkipToMainContent() {
            window.location.href = "#Id-page-host";
            $('html,body').scrollTop(0);
        }

        function activate() {
            headerSearchInProgress(false);
            if (session.genericLLABrand() == '') {
                logoComponent('viewmodels/_BrandLogo');
            }
            else {
                logoComponent('viewmodels/_genLogo');
            }
            //console.log(genericLogoURL());
            console.log('activate from Header');
        }

        function MobileSideNavToggle() {
            session.toggleSideNav(!session.toggleSideNav());
        }

    

        function initAutoSuggest() {
            console.log('Initializing top search' + ' control rendered ' + $('#txtTopSearchBox').length);
            
            //genericLogoURL('url("https://dev.passport.fntic.com/Content/images/Brands/FNTitle.png")');
            try {
                if (session.isLoggedIn() == true) {
                    var bingKey = session.bingMapAPIKey();
                    if (bingKey != "") {
                        map = new Microsoft.Maps.Map('#searchMap', {
                            credentials: bingKey,
                            center: new Microsoft.Maps.Location(47.606209, -122.332071),
                            zoom: 12,

                        });

                        Microsoft.Maps.loadModule(['Microsoft.Maps.AutoSuggest', 'Microsoft.Maps.Search'], function () {
                            var manager = new Microsoft.Maps.AutosuggestManager({ map: map, countryCode: 'US' });
                            manager.attachAutosuggest('#txtTopSearchBox', '#txtTopSearchBoxContainer', selectedSuggestionTop);
                            searchManager = new Microsoft.Maps.Search.SearchManager(map);
                        });
                        session.isMapAvailable(true);
                    }
                }
            }
            catch (ex) {
                session.isMapAvailable(false);
                console.log('map not available');
            }

            //Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', function () {
            //    var manager = new Microsoft.Maps.AutosuggestManager({ countryCode: 'US' });
            //    manager.attachAutosuggest('#txtTopSearchBox', '#txtTopSearchBoxContainer', selectedSuggestionTop);

            //});
        }

        function searchClear() {
            session.headerSearchText('');
        }

        function sso() {
            var url = config.getSsoUrl;
            https.get(url, {
                encPw: session.pass,
                ssoTarget: 'Admin',
                format: 'json'
            }).then(function (data) {
                var redirectUrl = data;
                session.CheckPopUpBlock(redirectUrl);
                //window.open(redirectUrl, '_blank');
            });
        }

        function selectedSuggestionTop(result) {
            if (result.matchCode == undefined || result.matchCode == 'Good' || result.matchCode == 'UpHierarchy') {
                $('#pac-input').val(result.address.formattedAddress);
                srchText = result.address.formattedAddress;
                handleSearch();
            }
            else {

            }
        }

        function mapLoadError() {

        }

        function handleSearch() {
            //if (headerSearchInProgress())
            //    return;

            if (srchText && srchText.length > 0) {
                headerSearchInProgress(true);
                session.isSearchFromHeader(true);
                session.headerSearchText(srchText);
                var url = '#bingAddressSearch/false/' + generateUUID();
                router.navigate(url, { replace: true, trigger: true });
            }
            else {
                alert.warning('Please provide the address to search.');
            }
        }

        function searchClick() {
            srchText = $('#txtTopSearchBox').val();
            handleSearch();
        }

        function generateUUID() {
            var d = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        };

    });
